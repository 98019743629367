import transactionsService from "../../../../services/transactionsService";

export default {
  name: "submitted-orders",
  components: {},
  props: [],
  data() {
    return {
      submittedOrders: "",
      submittedOrdersDetails: "",
      submittedStatuses: "",
      statuses: "",
      errors: "",
      refIdSearch: null,
      orderIdSearch: null,
      statusText: "همه",
      status: null,
      typeText: "همه",
      type: null,
      currentPage: 1,
      tableHeaders: [
        "نام شرکت",
        "نوع ارز",
        "کدپیگیری بیت‌راه",
        "کدپیگیری فروشگاه",
        "آدرس یکبارمصرف",
        "وضعیت پرداخت",
        "وضعیت",
        "عملیات"
      ]
    };
  },
  computed: {},
  mounted() {
    this.getSubmittedOrdersFunc();
    this.getSubmittedOrdersStatusFunc();
    this.getStatusFunc();
    this.getErrorFunc();
  },
  methods: {
    changeStatus(status, text) {
      this.status = status;
      this.statusText = text;
    },
    changeType(type, text) {
      this.type = type;
      this.typeText = text;
    },
    submitFilterSubmittedOrders() {
      if (this.refIdSearch === "") {
        this.refIdSearch = null;
      }
      if (this.orderIdSearch === "") {
        this.orderIdSearch = null;
      }
      transactionsService
        .getSubmittedOrders(
          0,
          localStorage.getItem("bitrahAdminAccessToken"),
          this.status,
          this.type,
          this.refIdSearch,
          this.orderIdSearch
        )
        .then(response => {
          this.currentPage = 1;
          this.submittedOrders = response.data;
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    getStatusFunc() {
      transactionsService.getSettleStatus().then(response => {
        this.statuses = response.data;
      });
    },
    getSubmittedOrdersStatusFunc() {
      transactionsService.getSubmittedOrdersStatus().then(response => {
        this.submittedStatuses = response.data;
      });
    },
    getErrorFunc() {
      transactionsService.getErrors().then(response => {
        this.errors = response.data;
      });
    },
    getSubmittedOrdersFunc() {
      transactionsService
        .getSubmittedOrders(
          0,
          localStorage.getItem("bitrahAdminAccessToken"),
          this.status,
          this.type
        )
        .then(response => {
          this.submittedOrders = response.data;
        });
    },
    getPaginationData(id) {
      transactionsService
        .getSubmittedOrders(
          id - 1,
          localStorage.getItem("bitrahAdminAccessToken"),
          this.status,
          this.type
        )
        .then(response => {
          this.submittedOrders = response.data;
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    checkOrder(address) {
      transactionsService
        .checkSubmittedOrder(
          address,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then(() => {
          this.getSubmittedOrdersFunc();
        })
        .catch(error => {
          this.$bvToast.toast(error.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    getDetails(address) {
      transactionsService
        .getDetailsSubmittedOrder(
          address,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then(response => {
          this.submittedOrdersDetails = response.data;
          this.$refs["my-modal"].show();
        })
        .catch(error => {
          this.$bvToast.toast(error.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    getPrepare(address) {
      transactionsService
        .getPrepareSubmittedOrder(
          address,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then(() => {
          this.getSubmittedOrdersFunc();
        })
        .catch(error => {
          this.$bvToast.toast(error.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    prepareToRefund(address) {
      transactionsService
        .prepareSubmittedOrderToRefund(
          address,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then(() => {
          this.getSubmittedOrdersFunc();
        })
        .catch(error => {
          this.$bvToast.toast(error.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    refund(address) {
      transactionsService
        .refundSubmittedOrder(
          address,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then(() => {
          this.getSubmittedOrdersFunc();
        })
        .catch(error => {
          this.$bvToast.toast(error.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    evacuate(address) {
      transactionsService
          .evacuateSubmittedOrder(
              address,
              localStorage.getItem("bitrahAdminAccessToken")
          )
          .then(() => {
            this.getSubmittedOrdersFunc();
          })
          .catch(error => {
            this.$bvToast.toast(error.data.message, {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                  "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          });
    }
  }
};
